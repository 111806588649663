import * as React from 'react';

import { Divider } from '@circleci/compass';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faTwitter,
  faGithub,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import { faRss } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MessageDescriptor, useIntl } from 'react-intl';

import {
  BottomFooterLinks,
  BottomFooterLinkContainer,
} from '~/components/Footer';
import { IntlLink } from '~/components/shared/Link';
import linkMessages from '~/links/messages';
import styled from '~/styled';

const currentYear = new Date().getFullYear();

const ContentWrapper = styled.div`
  width: 100%;
`;

const Outer = styled.footer(({ theme }) => {
  return `
    flex-shrink: 0;
    padding-bottom: 34px;
    @media (min-width: ${theme.mediaQueries.small}) {
      padding: 0;
    }
  `;
});

const DividerMobile = styled(Divider)(({ theme }) => {
  return `
    margin: 0 -16px;
    opacity: 50%;
    @media screen and (min-width: ${theme.mediaQueries.small}) {
      margin: 0;
    }
  `;
});

const BottomContainer = styled.div(({ theme }) => {
  return `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    line-height: 1.5;
    margin-top: ${theme.space.space16};
    @media screen and (min-width: ${theme.mediaQueries.small}) {
      flex-direction: row;
      margin-top: ${theme.space.space16};
    }
  `;
});

const FooterText = styled.div(({ theme }) => {
  return `
    color: ${theme.colors.n100};
    opacity: 50%;
    @media (min-width: ${theme.mediaQueries.medium}) {
      flex-grow: 1;
      text-align: right;
    }
  `;
});

export const SocialIcon = styled(FontAwesomeIcon)`
  width: 25px;
  height: auto;
`;

interface BottomFooterDatum {
  translationId: string;
  hrefTranslation: MessageDescriptor;
}

const bottomFooterData: BottomFooterDatum[] = [
  {
    translationId: 'footer.linkTerms.text',
    hrefTranslation: linkMessages.termsOfUse,
  },
  {
    translationId: 'footer.linkPrivacyPolicy.text',
    hrefTranslation: linkMessages.privacyPolicy,
  },
  {
    translationId: 'footer.linkSecurity.text',
    hrefTranslation: linkMessages.security,
  },
];

interface FooterLinkDatum {
  hrefTranslation: MessageDescriptor;
  icon: IconDefinition;
}

const footerData: FooterLinkDatum[] = [
  {
    hrefTranslation: linkMessages.blogFeed,
    icon: faRss,
  },
  {
    hrefTranslation: linkMessages.facebook,
    icon: faFacebookSquare,
  },
  {
    hrefTranslation: linkMessages.twitter,
    icon: faTwitter,
  },
  {
    hrefTranslation: linkMessages.github,
    icon: faGithub,
  },
  {
    hrefTranslation: linkMessages.linkedIn,
    icon: faLinkedinIn,
  },
];

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 24px;
`;

interface TopFooterLinkProps {
  hrefTranslation: MessageDescriptor;
  icon: IconDefinition;
}

const SocialLink = styled.div(({ theme }) => {
  return `
    margin-left: 20px;
    color: ${theme.colors.n100};
    opacity: 50%;
  `;
});

const FooterLink = ({ hrefTranslation, icon }: TopFooterLinkProps) => {
  return (
    <SocialLink>
      <IntlLink hrefTranslation={hrefTranslation}>
        <SocialIcon icon={icon} />
      </IntlLink>
    </SocialLink>
  );
};

const TopFooter = () => {
  return (
    <TopContainer>
      {footerData.map(({ hrefTranslation, icon }, i) => {
        return (
          <FooterLink
            key={`footer-icon-${i}`}
            hrefTranslation={hrefTranslation}
            icon={icon}
          />
        );
      })}
    </TopContainer>
  );
};

const BottomFooterLink = ({ data }) => {
  const intl = useIntl();
  return (
    <BottomFooterLinkContainer>
      <IntlLink
        hrefTranslation={data.hrefTranslation}
        theme='blueEnterprise'
      >
        {intl.formatMessage({
          id: data.translationId,
        })}
      </IntlLink>
    </BottomFooterLinkContainer>
  );
};

const BottomFooter = ({ isErrorPage }) => {
  const intl = useIntl();
  const copyrightMessage = intl.formatMessage(
    { id: 'footer.copyrightEnterprise' },
    { currentYear: currentYear },
  );
  return (
    <BottomContainer>
      {!isErrorPage && (
        <BottomFooterLinks>
          {bottomFooterData.map((data, i) => {
            return (
              <BottomFooterLink
                key={`footer-${data}-${i}`}
                data={data}
              />
            );
          })}
        </BottomFooterLinks>
      )}
      <FooterText>{copyrightMessage}</FooterText>
    </BottomContainer>
  );
};

interface Props {
  isErrorPage?: boolean;
}

const Footer = ({ isErrorPage }: Props) => {
  return (
    <Outer>
      <ContentWrapper>
        {isErrorPage && <TopFooter />}
        <DividerMobile color='n600' />
        <BottomFooter isErrorPage={isErrorPage} />
      </ContentWrapper>
    </Outer>
  );
};

export default Footer;
