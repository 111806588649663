import * as React from 'react';

import { useMe } from '@circleci/api-hooks/v1-1';
import { Flex, SmallHeading, PageLoadingIndicator } from '@circleci/compass';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';

import HeroEnterprise from '~/components/Hero/HeroEnterprise';
import MainEnterprise from '~/components/MainEnterprise';
import getHostname from '~/utils/getHostname';

const headContent = defineMessages({
  title: {
    id: 'vcsAuthorizePage.title',
    defaultMessage: 'Log in - CircleCI',
  },
  metaTitle: {
    id: 'vcsAuthorizePage.metaTitle',
    defaultMessage: 'Log in to access CircleCI',
  },
  metaDescription: {
    id: 'vcsAuthorizePage.metaDescriptionEnterprise',
    defaultMessage:
      'Log into your CircleCI account to view the status of your builds.',
  },
});

export default () => {
  const intl = useIntl();

  const { data: me, isLoading } = useMe();

  const router = useRouter();
  const returnTo = router.query['return-to'] as string;

  if (isLoading) {
    return <PageLoadingIndicator containerHeight={'100vh'} />;
  }

  if (me) {
    const url = returnTo
      ? decodeURIComponent(returnTo)
      : `https://app.${getHostname()}/home`;

    router.push(url);
    return <PageLoadingIndicator containerHeight={'100vh'} />;
  }

  return (
    <>
      <Head>
        <title>{intl.formatMessage(headContent.title)}</title>
        <meta
          name='title'
          content={intl.formatMessage(headContent.metaTitle)}
        />
        <meta
          name='description'
          content={intl.formatMessage(headContent.metaDescription)}
        />
      </Head>
      <Flex
        height='52px'
        backgroundColor='n600'
        alignItems='center'
        justifyContent='center'
      >
        <SmallHeading color='white'>
          Your account needs to be activated by an administrator before you can
          log in
        </SmallHeading>
      </Flex>
      <MainEnterprise>
        <HeroEnterprise />
      </MainEnterprise>
    </>
  );
};
