import * as React from 'react';

import { Divider } from '@circleci/compass';

import FooterEnterprise from '~/components/Footer/FooterEnterprise';
import HeaderEnterprise from '~/components/Header/HeaderEnterprise';
import styled from '~/styled';

const Wrapper = styled.div(({ theme }) => {
  return `
    display: flex;
    min-height: calc(100vh - 52px);
    flex-direction: column;
    padding: ${theme.space.space8} ${theme.space.space16};
    @media screen and (min-width: ${theme.mediaQueries.small}) {
      padding: ${theme.space.space16} ${theme.space.space40};
    }
  `;
});

const ContentWrapper = styled.div(({ theme }) => {
  return `
    width: 100%;
    max-width: 640px;
    @media screen and (min-width: ${theme.mediaQueries.small}) {
      margin: auto;
    }
  `;
});

const DividerMobile = styled(Divider)(({ theme }) => {
  return `
    margin: 0 -16px;
    opacity: 50%;
    @media screen and (min-width: ${theme.mediaQueries.small}) {
      display: none;
      padding: 0;
    }
  `;
});

const Inner = styled.div(({ theme }) => {
  return `
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: ${theme.colors.n900};
    color: ${theme.colors.white};
    @media screen and (min-width: ${theme.mediaQueries.small}) {
      flex: 1;
      padding: 40px;
    }
  `;
});

interface Props {
  children: React.ReactNode;
  isErrorPage?: boolean;
}

const Main = ({ children, isErrorPage }: Props) => {
  return (
    <Wrapper>
      <HeaderEnterprise />
      <DividerMobile color='n600' />
      <Inner>
        <ContentWrapper>{children}</ContentWrapper>
      </Inner>
      <FooterEnterprise isErrorPage={isErrorPage} />
    </Wrapper>
  );
};

export default Main;
