import * as React from 'react';

import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import Logo from '~/components/icons/Logo';
import { IntlLink, getQueryParams } from '~/components/shared/Link';
import { mediaMaxWidthMedium } from '~/components/shared/screenSizes';
import * as screenSizes from '~/components/shared/screenSizes';
import linkMessages from '~/links/messages';
import getHostname from '~/utils/getHostname';

const Wrapper = styled.div`
  width: 100%;
`;

const Outer = styled.div`
  width: 100%;
  z-index: 3;
`;

const Inner = styled.div`
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${mediaMaxWidthMedium} {
    justify-content: flex-start;
  }
`;

const LogoWrapper = styled.div`
  width: 105px;
  @media screen and (min-width: ${screenSizes.small}) {
    width: 132px;
  }
`;

const Header = () => {
  const router = useRouter(),
    queryParams = getQueryParams((router && router.asPath) || ''),
    returnTo = queryParams.get('return-to'),
    query = new Map();
  const hostname = getHostname();

  if (returnTo) {
    query.set('return-to', returnTo);
  }
  return (
    <Outer data-cy='header'>
      <Wrapper>
        <Inner>
          <LogoWrapper>
            <IntlLink
              pageHref={`https://${hostname}`}
              hrefTranslation={linkMessages.root}
              query={query}
              theme='white'
            >
              <Logo color='black' />
            </IntlLink>
          </LogoWrapper>
        </Inner>
      </Wrapper>
    </Outer>
  );
};

export default Header;
